import { openDB, deleteDB } from 'idb';
import difference from "lodash.difference";
import { isDevelopment } from "./env";
import { filterObj, forEachObj, getRandomInt } from "./utils";
import { CACHE_TYPE_IDB } from "./const";
import { CACHES } from "../config";
import { captureRollbarEvent } from './rollbar';

// Get dbname based on url
let dbName = window?.location?.hostname;
if (isDevelopment() || !dbName) {
    dbName = 'starboard';
}

let openedDb;

// Incrementing the VERSION number will force the client cache to start afresh
const VERSION = 16;

/**
 * Returns a list of all the IDB configured cache names
 * @returns {string[]}
 */
function getIdbCacheNames() {
    const idbCacheNames = [];
    forEachObj(CACHES, (cache, name) => {
        if (cache.type === CACHE_TYPE_IDB) {
            idbCacheNames.push(name);
        }
    });
    return idbCacheNames;
}

/**
 * Create database and initialise stores if necessary
 */
export async function getDb(retrying = false) {

    if (openedDb) {
        return openedDb;
    }

    try {
        captureRollbarEvent({ message: `Opening idb started` });

        openedDb = await openDB(dbName, VERSION, {
            /**
             * This browser tab is blocking another tab from upgrading the IDB database
             * Close the idb connection and refresh the page after a short delay.
             */
            blocking() {
                captureRollbarEvent({ message: `Browser tab is blocking another tab from upgrading idb` });
                openedDb.close();

                // Refresh the page after a short delay plus some randomness
                setTimeout(() => {
                    window.location.reload();
                }, 500 + getRandomInt(0, 1000));
            },

            async upgrade(db, oldVersion, newVersion) {
                captureRollbarEvent({ message: `Upgrading idb from ${oldVersion} to ${newVersion} started` });

                // Delete all stores when version has changed (but isn't new, oldVersion = 0)
                if (oldVersion !== 0 && oldVersion !== newVersion) {
                    [...db.objectStoreNames].forEach((name) => {
                        db.deleteObjectStore(name);
                    });
                }

                // Create new stores
                const idbCaches = filterObj(CACHES, (obj) => obj.type === CACHE_TYPE_IDB);
                forEachObj(idbCaches, (v, i) => {
                    const opt = {};

                    // Add optional keyPath
                    if (v.key) {
                        opt.keyPath = v.key;
                    }

                    const store = db.createObjectStore(i, opt);
                    if (v.createdIndex) {
                        store.createIndex('created', '_created');
                    }
                });

                captureRollbarEvent({ message: `Upgrading idb from ${oldVersion} to ${newVersion} finished` });
            },

            blocked(currentVersion, blockedVersion) {
                captureRollbarEvent({ message: `Opening idb blocked. Current version: ${currentVersion}, blocked version: ${blockedVersion}` });
            },

            terminated() {
                captureRollbarEvent({ message: `Opening idb terminated` });
            },
        });

        captureRollbarEvent({ message: `Opening idb finished` });

    } catch (error) {
        // If there's a version mismatch, try deleting the db and re-opening the db
        // After the first attempt at this, throw an error
        // This should never happen to an actual user, most common case is switching between
        // different development versions
        if (error.name === "VersionError" && !retrying) {
            captureRollbarEvent({ message: `Idb version error. Deleting started.` });
            await deleteDB(dbName);
            captureRollbarEvent({ message: `Idb version error. Deleting finished.` });
            return getDb(true);
        }
        throw error;
    }

    // Each the time cache idb is opened, sanity check that the existing client store names match the config store names
    // If they don't match, delete the database and recreate
    const idbCacheNames = getIdbCacheNames();
    if (difference(openedDb.objectStoreNames, idbCacheNames).length > 0) {
        captureRollbarEvent({ message: `Opening idb but need to delete it first: started` });
        await deleteDB(dbName);
        captureRollbarEvent({ message: `Opening idb but need to delete it first: finished` });

        return getDb(true);
    }

    return openedDb;
}
