import CacheMem from "./CacheMem";
import CacheIdb from "./CacheIdb";
import {
    CACHE_H3,
    CACHE_OVERRIDE_H3_IDS,
    CACHE_TYPE_IDB,
    CACHE_TYPE_IDB_CACHE_OVERRIDE_H3_IDS,
    CACHE_TYPE_MEM,
    CACHE_TYPE_MEM_OBJ,
    CACHE_TYPE_MEM_SORTED,
    FORWARD,
} from "./const";
import { CACHES } from "../config";
import { asyncForEachObj, binarySearchIncludes, getRandomInt, promiseAllWithKeys } from "./utils";
import CacheDevNull from "./CacheDevNull";
import { getDb } from "./local-cache-idb";
import { urlGet } from "./url";
import CacheMemSorted from "./CacheMemSorted";
import CacheMemObj from "./CacheMemObj";
import { captureRollbarEvent } from "./rollbar";
import { getH3IndexFromKey } from "./h3";

const caches = {};

// Check if the browser can open an indexDB database.
let hasIndexDb = true;
let detectionRun = false;

export async function detectIndexDb() {
    try {
        await getDb();
    } catch (e) {
        hasIndexDb = false;
    }
    detectionRun = true;
}

/**
 * Init cache
 * @param name
 * @param opt
 * @return {*}
 */
function init(name, opt) {

    caches[name] = { ...opt };

    // Allow for disabling all the client caches with `debugDisableCache=true` url parameter
    if (urlGet('debugDisableCache')) {
        console.log(`${name} browser cache disabled.`);
        caches[name].obj = new CacheDevNull(name, opt.key);
        return caches[name].obj;
    }

    if (opt.type === CACHE_TYPE_MEM) {
        caches[name].obj = new CacheMem(name, opt.key, opt);
        return caches[name].obj;
    }

    if (opt.type === CACHE_TYPE_MEM_SORTED) {
        caches[name].obj = new CacheMemSorted(name, opt.key, opt);
        return caches[name].obj;
    }

    if (opt.type === CACHE_TYPE_MEM_OBJ) {
        caches[name].obj = new CacheMemObj(name, opt.key, opt);
        return caches[name].obj;
    }

    if (opt.type === CACHE_TYPE_IDB) {
        if (!detectionRun) {
            throw new Error("Cache IDB detection not run yet");
        }

        if (hasIndexDb) {
            caches[name].obj = new CacheIdb(name, opt.key, opt);
        } else {
            // If there's no ability to use an index db database, use a DevNull cache instead (no caching)
            // the app will then at least work, even if it's slower
            caches[name].obj = new CacheDevNull(name, opt.key);
        }
        return caches[name].obj;
    }

    throw new Error(`Unknown cache type: '${opt.type}'`);
}

/**
 * @param name
 * @return {CacheIdb|CacheMem|CacheMemSorted|CacheMemObj|CacheDevNull}
 */
export function getCache(name) {
    if (!CACHES[name]) {
        throw new Error(`Cache '${name}' not defined`);
    }

    // Check if cache has already been initialised
    if (caches[name]) {
        return caches[name].obj;
    }

    return init(name, CACHES[name]);
}

/**
 * When removing override h3 ids, we also need to remove any matching cached items
 * @param {string[]} overrideH3Ids
 * @returns {Promise<string[]>}
 */
async function removeMatchingOverriddenCells(overrideH3Ids) {
    if (overrideH3Ids.length > 0) {
        const allKeys = await getCache(CACHE_H3).allKeys();
        const keysToDelete = [];
        allKeys.forEach((key) => {
            if (overrideH3Ids.includes(getH3IndexFromKey(key))) {
                keysToDelete.push(key);
            }
        });

        if (keysToDelete.length > 0) {
            await getCache(CACHE_H3).deleteMultiple(keysToDelete);
        }
    }
    return overrideH3Ids;
}

/**
 * Remove stale items from all indexdb caches with a createdIndex
 * @return {Promise<unknown[]>}
 */
async function removeStaleCacheItems() {
    const cachesWithCreated = [];
    await asyncForEachObj(CACHES, async (cache, name) => {
        if (cache.createdIndex && (cache.type === CACHE_TYPE_IDB || cache.type === CACHE_TYPE_IDB_CACHE_OVERRIDE_H3_IDS)) {
            cachesWithCreated.push(await getCache(name));
        }
    });

    const promiseObjs = {};
    cachesWithCreated.forEach((cache) => {
        if (cache.name === CACHE_OVERRIDE_H3_IDS) {
            // When we clear out the overridden h3 ids also clear the cached h3 cells
            promiseObjs[cache.name] = cache.deleteByTtl(512).then((overrideH3Ids) => {
                return removeMatchingOverriddenCells(overrideH3Ids);
            });
        } else {
            // Delete a maximum of 1024 things per cache (it's quite slow!)
            promiseObjs[cache.name] = cache.deleteByTtl(1024);
        }
    });

    return promiseAllWithKeys(promiseObjs);
}

/**
 * Allows override of cache config (used for tests)
 * @param {string} name
 * @param {{}} opt
 */
export function overrideCache(name, opt) {
    init(name, opt);
}

export async function clearAllCaches() {
    return Promise.all(Object.keys(CACHES).map(async (name) => {
        const cacheObj = getCache(name);
        await cacheObj.clear();
    }));
}

/**
 * Filter an array of cache keys containing a timestamp (at keyStartTsPos) by a given timestamp and direction
 * @param {string[]} keys
 * @param {number} keyStartTsPos
 * @param {number} ts
 * @param {string} direction
 * @return {*}
 */
function filterCacheKeys(keys, keyStartTsPos, ts, direction) {
    if (direction === FORWARD) {
        return keys.filter((key) => {
            return Number(key.substring(keyStartTsPos, keyStartTsPos + 10)) >= ts;
        });
    }

    // Backwards
    return keys.filter((key) => {
        return Number(key.substring(keyStartTsPos, keyStartTsPos + 10)) <= ts;
    });
}

/**
 * Remove items from the specified cache based on a passed timestamp and direction
 * @param {string} cacheName
 * @param {number} ts
 * @param {string} direction
 * @return {Promise<*>}
 */
export async function removeFromCacheByKeyTs(cacheName, ts, direction) {
    const cache = getCache(cacheName);
    const keys = await cache.allKeys();
    const toDelete = filterCacheKeys(keys, cache.keyStartTsPos, ts, direction);

    if (toDelete.length > 0) {
        // Add rollbar telemetry to help debug forever loading app
        captureRollbarEvent({ message: `removeFromCacheByKeyTs: Purging ${toDelete.length} items from ${cacheName} cache...` });
        await cache.deleteMultiple(toDelete);
        captureRollbarEvent({ message: "removeFromCacheByKeyTs: Done" });
    }
    return toDelete;
}

/**
 * Returns two arrays with what keys are in the cache and not in the cache
 * Use binary search and reduce here for speed
 * @param {string[]} keys
 * @param {Object} cache
 * @returns {Promise<{notInCache: *, inCache: *}>}
 */
export async function keysInCache(keys, cache) {
    const sortedAllKeysInCache = await cache.allKeys();
    sortedAllKeysInCache.sort();

    return keys.reduce((acc, curr) => {
        if (binarySearchIncludes(curr, sortedAllKeysInCache)) {
            acc.inCache.push(curr);
        } else {
            acc.notInCache.push(curr);
        }
        return acc;
    }, {
        inCache: [],
        notInCache: [],
    });
}

/**
 * Do the clearing and schedule another run, broken out into another function because Safari doesn't currently
 * support window.requestIdleCallback
 */
function removeStaleCacheItemsLoopInside() {

    removeStaleCacheItems().then((result) => {
        // If cache items were removed, wait ~1 minute, otherwise wait ~10 minutes before trying again
        let timeout;
        if (Object.keys(result).some((key) => result[key].length > 0)) {
            timeout = 60000 + getRandomInt(0, 5000);
        } else {
            timeout = 600000 + getRandomInt(0, 5000);
        }

        // Re-run after timeout
        setTimeout(removeStaleCacheItemsLoop, timeout);
    });
}

/**
 * Runs a loop to check for state cache items every x seconds
 * Uses requestIdleCallback so this runs when the app isn't busy
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
 */
export function removeStaleCacheItemsLoop() {
    if (window.requestIdleCallback) {
        window.requestIdleCallback(removeStaleCacheItemsLoopInside);
    } else {
        // Safari doesn't support window.requestIdleCallback
        removeStaleCacheItemsLoopInside();
    }
}
