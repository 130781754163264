export const INCREMENT_DATA_LOADING = 'INCREMENT_DATA_LOADING';
export const DECREMENT_DATA_LOADING = 'DECREMENT_DATA_LOADING';
export const SET_DATA_LOADING_TEXT = 'SET_DATA_LOADING_TEXT';

export const initialState = {
    text: null,
    count: 0,
    stack: [],
};

/**
 * Set the number of cells that need to be downloaded
 * @returns {Object}
 * @param {string} text
 */
export function setDataLoadingText(text) {
    return {
        type: SET_DATA_LOADING_TEXT,
        text: text,
    };
}

/**
 * Increment the data loading counter
 * @param {Object} args
 * @param {string|null} args.text Text to render in the loading bubble
 * @param {string|null} args.label Purely for debugging purposes
 * @param {string|null} args.loadingType Used to derive whether a certain type of loading is occuring
 * @return {{type: string, text: string|null, label: string|null, loadingType: string|null}}
 */
export function incrementDataLoading({ text = null, label = null, loadingType = null } = {}) {
    return {
        type: INCREMENT_DATA_LOADING,
        text: text,
        label: label,
        loadingType: loadingType,
    };
}

/**
 * Decrement the data loading counter
 * @param {Object} args
 * @param {string|null} args.label Purely for debugging purposes
 * @param {string|null} args.loadingType Used to derive whether a certain type of loading is occuring
 * @return {{label: null, type: string, loadingType: string|null}}
 */
export function decrementDataLoading({ label = null, loadingType = null }) {
    return {
        type: DECREMENT_DATA_LOADING,
        label: label,
        loadingType: loadingType,
    };
}

export function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case INCREMENT_DATA_LOADING: {

            let text = state.text;
            if (action.text) {
                text = action.text;
            }

            return {
                ...state,
                text: text,
                count: state.count + 1,
                stack: action.loadingType ? state.stack.concat(action.loadingType) : state.stack,

                // if there's an identifier for the type of loading, update related text of that loading type to have it available on decrement action
                relatedText: action.loadingType ? { ...state.relatedText, [action.loadingType]: action.text } : state.relatedText,
            };
        }
        case DECREMENT_DATA_LOADING: {
            let newStack = state.stack;

            // Remove the last instance of this loadingType from stack
            if (action.loadingType) {
                const lastIndex = state.stack.lastIndexOf(action.loadingType);
                if (lastIndex !== -1) {
                    newStack = [...state.stack];
                    newStack.splice(lastIndex, 1);
                }
            }

            const newState = {
                ...state,
                count: Math.max(state.count - 1, 0),
                stack: newStack,
                text: state.relatedText[newStack[newStack.length - 1]],
            };

            // Clear text when loading gets to 0
            if (newState.count === 0) {
                newState.text = "";
            }

            return newState;
        }
        case SET_DATA_LOADING_TEXT: {
            if (action.text === state.text) {
                return state;
            }
            return {
                ...state,
                text: action.text,
            };
        }
        default:
            return state;
    }
}
